.spacer{
    aspect-ratio: 960/300;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    
}
.layered-waves{
    background-image: url(../content//assets/stacked-waves.svg);
}